<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add FAQ</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Product Category (*)</span>
            <v-select
              label="name"
              :options="productCategoryOptions"
              :reduce="category => category.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.category_product_id"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("category_product_id")
            }}</span>
          </vs-col>

      <vs-col vs-w="12" class="mb-3">
        <vs-input class="w-full" label="Title (*)" v-model="payload.title" />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("title")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mb-3">
        <span class="mb-2 block text-sm">Content (*)</span>
        <input type="file" @change="uploadMedia" id="file" hidden />
        <quill-editor
          v-model="payload.content"
          :options="editorOption"
          @ready="onEditorReady($event)"
          ref="myQuillEditor"
        ></quill-editor>
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("content")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mt-3">
        <div class="vx-col w-full" @click.prevent="storeData">
          <vs-button class="mr-3 mb-2">Submit</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from '@/axios'
import vSelect from "vue-select";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module';
import { quillEditor } from "vue-quill-editor";
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/imageDrop', ImageDrop);

// Store Module
import moduleFaqManagement from "@/store/faq-management/moduleFaqManagement.js";

export default {
  name: "FaqAdd",

  metaInfo: {
    title: "Add FAQ",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect,
    quillEditor
  },

  data: () => ({
    productCategoryOptions: [],

    editorOption: {
      // some quill options
      modules: {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"], // remove formatting button
            ["link", "image"]
          ],
          handlers: {
            image: function() {
              document.getElementById("file").click();
            }
          }
        },
        imageResize: {
            modules: ['Resize', 'DisplaySize', 'Toolbar']
        },
        imageDrop: true
      }
    },

    payload: {
      category_product_id: '',
      title: "",
      content: ""
    }
  }),

  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },

  methods: {
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },

    uploadMedia(e) {
      const file = e.target.files[0];

      const form = new FormData();
      form.append("media", file);

      axios
        .post("faq-media", form, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(({ data: res }) => {
          // set position cursor
          const range = this.editor.getSelection();
          // set image on the server to quill editor
          this.editor.insertEmbed(range.index, "image", res.data);
        })
        .catch(err => this.$catchErrorResponse(err));
    },

    getProductCategories() {
      axios
        .get("/category-product")
        .then(({ data: res }) => {
          this.productCategoryOptions = res.data.map(category => {
            return {
              id: category.id,
              name: category.name
            };
          });
        })
        .catch(err => this.$catchErrorResponse(err));
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set("category_product_id", this.payload.category_product_id);
      formData.set("title", this.payload.title);
      formData.set("content", this.payload.content);

      this.$store
        .dispatch("faqManagement/storeFaq", formData)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/company-content/faq");
        })
        .catch(err => {
          this.$catchErrorResponse(err)
        });
    }
  },

  created() {
    if (!moduleFaqManagement.isRegistered) {
      this.$store.registerModule("faqManagement", moduleFaqManagement);
      moduleFaqManagement.isRegistered = true;
    }

    this.getProductCategories()
  }
};
</script>

<style></style>
